import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

import Icon from 'components/Icon'

import styles from './Select.module.scss'

const Select = ({ selected, options }) => {
  const [showOptions, toggleOptions] = useState(false)

  const handleOptionsClose = () => {
    toggleOptions(false)
    document.removeEventListener('click', handleOptionsClose)
  }

  useEffect(() => {
    if (showOptions) {
      document.addEventListener('click', handleOptionsClose);
    }
  }, [showOptions])

  return (
    <div className={styles.select}>
      <div className={cx(styles.selected, {[styles.selectedActive]: showOptions})} onClick={() => toggleOptions(!showOptions)}>
        {options[selected].text}
        { showOptions
          ? <Icon icon={['fas', 'caret-up']} className={styles.caretIcon} />
          : <Icon icon={['fas', 'caret-down']} className={styles.caretIcon} />
        }
      </div>
      { showOptions && 
        <div className={styles.optionList}>
          {options.map((option, idx) => <Link key={idx} className={styles.optionItem} to={option.to}>{option.text}</Link>)}
        </div>
      }
    </div>
  )

}

export default Select