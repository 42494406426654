import React from 'react'

import styles from './PartnersLinks.module.scss'

const PartnersLinks = ({ links }) => {
  return (
    <ul className={styles.list}>
      {links.map((link, idx) => <li key={idx}><a className={styles.link} href={link.url} target='_blank' rel='noopener'>{link.name}</a></li>)}
    </ul>
  )
}

export default PartnersLinks