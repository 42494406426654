import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/SEO'
import Select from 'components/Select'
import ModelCard from 'components/ModelCard'
import PartnersLinks from 'components/PartnersLinks'

import styles from './ModelList.module.scss'

const LatestModelsPage = ({ data }) => {
  const { models, site: { siteMetadata }, banner } = data

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/models/`,
          "name": "Latest models"
        }
      },
    ]
  }

  const sloppyPartners = []
  
  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`Enjoy our fresh added camgirls ready for some live cam sex | SloppyDeep`}
      description={`Have fun with our brand new camgirls here on the best recording cam tube. SloppyDeep has the biggest archive of cam models: from teen to milf, asian to russian, redhead to blonde, just pick your favorite and enjoy the show!`}
      keywords={`best cam models, hottest cam girls, best cam girl, camgirl tube, hottest cam girls, new camgirls`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/models/`}
      robots='index, follow'
    />
    <main className={`${styles.modelListScreen} screen-container`}>
      <div className={styles.titleBlock}>
        <div className={styles.sectionTitle}>Our Models</div>
        <div className={styles.sectionFilter}>
          <Select selected={0} options={[{ text: 'Latest', to: '/models/' }, { text: 'Popular', to: '/models/popular/' }]} />
        </div>
      </div>
      <div className={`${styles.modelsBlock} model-cards-container`}>
        {models.nodes.map((model, idx) => <ModelCard key={idx} data={model} />)}
      </div>

      {sloppyPartners.length > 0 &&
        <>
          <div className={styles.titleBlock}>Sloppy Partners</div>
          <div className={styles.partnersLinksBlock}>
            <PartnersLinks links={sloppyPartners} />
          </div>
        </>
      }

    </main>
    </>
  )
}

export const query = graphql`
  query LatestModelsPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    models: allSloppyDeepModel(sort: {fields: publishDate, order: DESC}) {
      nodes {
        slug
        primaryAka {
          nickname
        }
        profilePicture {
          childImageSharp {
            fluid(maxWidth: 353, maxHeight: 450, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        rating
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

export default LatestModelsPage
