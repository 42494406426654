import React, { useState } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import cx from 'classnames'
import StarRatings from 'react-star-ratings'

import styles from './ModelCard.module.scss'

const Card = ({ data }) => {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <article className={styles.modelCard} >
      <Link className={styles.thumbnail} to={`/models/${data.slug}/`} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} aria-label={data.primaryAka.nickname}>
        <Img fluid={{...data.profilePicture.childImageSharp.fluid, aspectRatio: 18/23 }} className={styles.thumbnailImg} />
      </Link>

      <Link to={`/models/${data.slug}/`} className={cx(styles.nickname, { [styles.nicknameActive]: isHovering })}>
        <h1>{data.primaryAka.nickname}</h1>
      </Link>
      <div className={styles.rating}>
        <StarRatings
          rating={data.rating}
          starEmptyColor="#535353"
          starRatedColor="#EBEBEB"
          starDimension=".8125rem"
          starSpacing=".01rem"
        />
      </div>
    </article>
  )
}

export default Card